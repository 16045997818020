(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mobx"), require("React"), require("sbInternalMsgBus"), require("sbBffIntegration"), require("mobxReact"), require("sbRespBlockLib"), require("SBTech"), require("sbDataLayer"), require("sbJsExtends"), require("ReactDOM"), require("gsap"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-filter-block", ["mobx", "React", "sbInternalMsgBus", "sbBffIntegration", "mobxReact", "sbRespBlockLib", "SBTech", "sbDataLayer", "sbJsExtends", "ReactDOM", "gsap"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-filter-block"] = factory(require("mobx"), require("React"), require("sbInternalMsgBus"), require("sbBffIntegration"), require("mobxReact"), require("sbRespBlockLib"), require("SBTech"), require("sbDataLayer"), require("sbJsExtends"), require("ReactDOM"), require("gsap"));
	else
		root["sb-responsive-filter-block"] = factory(root["mobx"], root["React"], root["sbInternalMsgBus"], root["sbBffIntegration"], root["mobxReact"], root["sbRespBlockLib"], root["SBTech"], root["sbDataLayer"], root["sbJsExtends"], root["ReactDOM"], root["gsap"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__68__, __WEBPACK_EXTERNAL_MODULE__71__, __WEBPACK_EXTERNAL_MODULE__72__) {
return 